import React, { useContext, useRef, useState, useMemo } from 'react';
import { BTSpinner, BTButton, BTIcon, BTCheckbox, BTForm, BTComboBox, BTAlert } from '@btas/jasper';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import {
  DELETE_SOURCE_FILE,
  WKP_INPUT_FILE_IMPORT,
  WKP_INPUT_SINGLE_FILE_IMPORT,
  WKP_CONFIG_PANEL_PAGINATION,
} from '../../../../constants/featureFlags';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import '../InputElementInspector/styles.scss';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { removeOriginUploadS3File, removeSourceFile } from '../InputElementInspector/apis';
import {
  useJobProcessor,
  JOB_PROCESSOR_STATUS_RETRY_CANCELLED,
  JOB_PROCESSOR_STATUS_CANCELLED,
} from '../../DataFlowJobsContext';
import { useCanEditWorkflow } from '../../../_shared/UserPermissionsContext';
import { DATE, NUMERIC, TEXT } from '../shared/fieldTypes';
import { getSheetNames } from '../FileDialog/apis';
import { removeUplodFilePermanently, validateIsPermanentDelete } from '../InputElementInspector/removeSourceFile';
import {
  FILE_COMPLETED_STATUS,
  FILE_IMPORT_RUNNING_STATUS,
  FILE_UPLOAD_RUNNING_STATUS,
} from './FilePropertiesStatusTypes';
import { PaginationContainer } from '../shared/PaginationContainer';
import { INSPECTOR_PAGE_SIZE } from '../shared/constants/pagination';
import { STEP_CANCEL } from '../InputElementInspector/useImportFile';

const FilePropertiesPanel = ({
  dataFlowActions,
  dataFlowState,
  elementId,
  elementData,
  updateData,
  sourceFiles,
  setSourceFiles,
}) => {
  const { setWKPFileImportProperties, commitWorkingElement } = dataFlowActions;
  const { wkpFileImportProperties, id: dataflowId, taxPeriod, workingElement } = dataFlowState;
  const { sourceFileUpload, showConfirmationModal, fileImport } = useContext(DataFlowEditorContext);
  const { cancelJob, bindOnStatusChanged } = useJobProcessor();
  const { updateImportFileState, isIngestRunning, ingestWithElementId } = fileImport;
  const { uploadedFilesByInput, setUploadedFilesByInput, isUploadingWithImport, uploadingWithImportElementId } =
    sourceFileUpload;
  const inputFileImportFlag = isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT);
  const inputSingleFileImportFlag = isFeatureFlagEnabled(WKP_INPUT_SINGLE_FILE_IMPORT);
  const fileNameCharsLimit = 25;

  const deleteSourceFileFlag = isFeatureFlagEnabled(DELETE_SOURCE_FILE);
  const { url } = useRouteMatch();
  const history = useHistory();

  const total = elementData.fields?.length;
  const isPaginationEnabled = useRef(isFeatureFlagEnabled(WKP_CONFIG_PANEL_PAGINATION));
  const [page, setPage] = useState(1);

  const fields = useMemo(() => {
    const start = (page - 1) * INSPECTOR_PAGE_SIZE;
    const end = page * INSPECTOR_PAGE_SIZE;

    if (isPaginationEnabled.current === false) {
      return elementData.fields;
    }

    return elementData.fields?.slice(start, end);
  }, [elementData.fields, page]);

  const dataTypeOptions = [
    { label: 'Text', value: TEXT },
    { label: 'Number', value: NUMERIC },
    { label: 'Date', value: DATE },
  ];
  const getFileNameCharsLimit = 25;

  const getName = uploadedFile => {
    return uploadedFile?.name ?? null;
  };

  const truncatedName = (name, length) => {
    return name?.length > length ? `${name.substr(0, length)}...` : name;
  };

  const getTruncatedName = (uploadedFile, length) => {
    const fileName = getName(uploadedFile);
    return truncatedName(fileName, length);
  };

  const getSheetName = (sheetName, activeVersion) => {
    return activeVersion ? activeVersion.sheetName : sheetName;
  };

  const onFieldTypeChange = (pageIndex, { value }) => {
    const index = isPaginationEnabled.current ? (page - 1) * INSPECTOR_PAGE_SIZE + pageIndex : pageIndex;
    const newFields = [...elementData.fields];
    const { derived_date_format: derivedDateFormat } = newFields[index];
    //If field type is not a date, date format should be empty. Otherwise, check if date format has been derived. If not, use default date format.
    const newDateFormatValue =
      value === 'date' ? (derivedDateFormat ? derivedDateFormat : "yyyy-MM-dd'T'HH:mm:ss.SSSXXX") : '';

    newFields[index] = { ...elementData.fields[index], type: value, date_format: newDateFormatValue };

    updateData({ fields: newFields });
  };

  const getUploadedInputFile = () => {
    const uploadedFile = uploadedFilesByInput[elementId];

    return uploadedFile;
  };

  const getElementTitle = file => {
    return file?.name.length > fileNameCharsLimit ? getName(file) : '';
  };

  const onSelectDataWithinFile = () => {
    const fileExtension = elementData?.uploadedFile?.name.split('.').pop();
    const isCsvFile = fileExtension === 'csv';
    setWKPFileImportProperties({
      ...wkpFileImportProperties,
      popupDataDialog: true,
      fileName: elementData?.uploadedFile?.name,
      isCsvFile: wkpFileImportProperties?.isCsvFile || isCsvFile,
    });
  };

  const clearUploadInput = updateData => {
    updateData({
      ...elementData,
      integrationType: '',
      uploadedFile: undefined,
    });
    const newUploadedFiles = uploadedFilesByInput;
    delete newUploadedFiles[elementId];
    setUploadedFilesByInput(newUploadedFiles);
  };

  const onCloseJob = () => {
    const { bucket, location: path } = uploadedFilesByInput[elementId];
    const onStatusChange = status => {
      if (status === JOB_PROCESSOR_STATUS_RETRY_CANCELLED) {
        cancelJob({
          processId: elementId,
          callback: removeOriginUploadS3File,
          params: [dataflowId, { bucket, path, name: JOB_PROCESSOR_STATUS_RETRY_CANCELLED }],
        });
        clearUploadInput(updateData);
      }
    };
    bindOnStatusChanged(elementId, onStatusChange);
    cancelJob({
      processId: elementId,
      callback: removeOriginUploadS3File,
      params: [dataflowId, { bucket, path, name: JOB_PROCESSOR_STATUS_CANCELLED }],
    });
    clearUploadInput(updateData);
    const parentUrl = url.substring(0, url.lastIndexOf('/'));
    history.replace(`${parentUrl}`);
  };

  const onChangeAppendDataSource = checked => {
    const { sheetName } = sourceFiles[0];
    const { name } = elementData?.uploadedFile;
    const newFields = [...elementData?.fields];
    const appendDataSource = { appendSource: checked, fileName: name, sheetName, taxPeriod };
    if (checked) {
      const dataSourceCount = elementData?.fields?.reduce(
        (acum, field) =>
          field.name?.includes('Data Source') || field.original_name?.includes('Data Source') ? acum + 1 : acum,
        0
      );
      const dataSourceName = dataSourceCount > 0 ? `Data Source.${dataSourceCount}` : 'Data Source';
      newFields.push({ name: dataSourceName, type: 'text', date_format: 'MM/dd/yyyy' });
    } else {
      newFields.pop();
    }
    updateData({ ...elementData, fields: newFields, appendDataSource });
  };

  const editFile = async (
    sourceFileId,
    uploadedFile,
    sheetTabName,
    numHeaders,
    dataStartAt,
    fileBucket,
    fileKey,
    activeVersion
  ) => {
    const name = getName(uploadedFile);
    let path = fileKey,
      bucket = fileBucket,
      sheetNames = null,
      headerRows = numHeaders,
      headersStartAt = dataStartAt;
    const fileExtension = uploadedFile.name.split('.').pop();
    const isCsvFile = fileExtension === 'csv';

    if (activeVersion) {
      path = activeVersion.fileKey;
      bucket = activeVersion.bucket;
    }

    if (!isCsvFile) {
      path = uploadedFile.location; // get original xlsx path for reuse

      const result = await getSheetNames({ bucket, path, name });
      sheetNames = result.sheetNames;
    }
    const savedHeaderRows = sourceFiles[0]?.headerRows;
    const savedHeadersStartAt = sourceFiles[0]?.headersStartAt;
    const savedSheetName = sourceFiles[0]?.sheetName;

    headerRows = activeVersion?.headerRows || savedHeaderRows;
    headersStartAt = activeVersion?.headersStartAt || savedHeadersStartAt;

    setWKPFileImportProperties({
      ...wkpFileImportProperties,
      popupDataDialog: true,
      loading: false,
      fileName: name,
      isCsvFile,
      fileDialogTaxPeriod: uploadedFile?.taxPeriod ?? taxPeriod,
      sheets: !isCsvFile
        ? sheetNames.map(sheet => {
            return { value: sheet.name, label: sheet.name };
          })
        : [],
      savedValues: {
        sheetName: getSheetName(sheetTabName, activeVersion) || savedSheetName,
        headerRows,
        headersStartAt,
      },
      sourceFileId,
      updateSourceFile: true,
    });
  };

  const sheetHeaderDetails = (sheetName, headerRows, headersStartAt) => (
    <p className="wkp-input-sheet-details">
      Sheet: <span title={sheetName.length > 23 ? sheetName : ''}>{truncatedName(sheetName, 23)}</span> | Headers starts
      at: {headersStartAt} | Header row: {headerRows}
    </p>
  );

  const deleteSourceFileModal = async (name, sourceFileId) => {
    if (deleteSourceFileFlag) {
      const deleteResp = await validateIsPermanentDelete(dataFlowState.id, elementId);
      if (deleteResp.permanentDeleteInfo && deleteResp.permanentDeleteInfo.length > 0) {
        //we have to check both file.id and sourceFileId because pending files will be retrieved
        //as source files versions and won't have id
        showConfirmationModal(
          'Delete Source File',
          `The source file '${name}' will be permanently deleted. Are you sure?`,
          'Delete',
          'Cancel',
          'warning',
          async () => {
            if (inputFileImportFlag) {
              const { deletePath } = elementData;
              await removeOriginUploadS3File(dataflowId, { path: deletePath });
              const newUploadedFiles = uploadedFilesByInput;
              delete newUploadedFiles[elementId];
              setUploadedFilesByInput(newUploadedFiles);
            }
            await removeSourceFile(sourceFileId, dataFlowState.id, elementId);
            await removeUplodFilePermanently(deleteResp.permanentDeleteInfo, elementData.integrationType.toLowerCase());
            const newSourceFiles = sourceFiles.filter(
              file => file.id !== sourceFileId && file.sourceFileId !== sourceFileId
            );

            setSourceFiles(newSourceFiles);
            updateData({
              fields: undefined,
              pendingSourceFileVersionId: undefined,
              import: undefined,
              sourceFields: undefined,
              ...(inputFileImportFlag && {
                uploadedFile: undefined,
                integrationType: '',
              }),
            });
            commitWorkingElement();
            history.replace(`/data-flows/${dataflowId}/editor`);
          }
        );
      } else {
        await removeSourceFile(sourceFileId, dataFlowState.id, elementId);
        const newSourceFiles = sourceFiles.filter(
          file => file.id !== sourceFileId && file.sourceFileId !== sourceFileId
        );
        setSourceFiles(newSourceFiles);
        if (newSourceFiles.length) {
          updateData({
            containsNewSourceFiles: false,
            isSourceFileDeleted: true,
            ...(inputFileImportFlag && {
              uploadedFile: undefined,
              integrationType: '',
            }),
          });
        } else {
          updateData({
            fields: undefined,
            pendingSourceFileVersionId: undefined,
            import: undefined,
            sourceFields: undefined,
            ...(inputFileImportFlag && {
              uploadedFile: undefined,
              integrationType: '',
            }),
          });
        }
        if (inputFileImportFlag) {
          const newUploadedFiles = uploadedFilesByInput;
          delete newUploadedFiles[elementId];
          setUploadedFilesByInput(newUploadedFiles);
        }
        commitWorkingElement();
        history.replace(`/data-flows/${dataflowId}/editor`);
      }
    }
  };

  const onCancelImport = () => {
    updateImportFileState(workingElement?.id, {
      currentStep: STEP_CANCEL,
    });
  };

  const renderUploadFilePill = () => {
    return (
      <>
        {elementData?.uploadedFile?.status === FILE_UPLOAD_RUNNING_STATUS && (
          <>
            <div className="wkp-upload-file-status">
              <div className="wkp-upload-file-elements">
                <div className="wkp-upload-file-spinner-wrapper">
                  <BTSpinner size="1x" />
                </div>
                <span
                  style={{ fontWeight: 'bold' }}
                  title={getElementTitle(getUploadedInputFile() || elementData?.uploadedFile)}
                >
                  Uploading '{getTruncatedName(getUploadedInputFile() || elementData?.uploadedFile, fileNameCharsLimit)}
                  '
                </span>
              </div>

              <button className="wkp-progress-bar-btn-close" type="button" onClick={onCloseJob}>
                <BTIcon icon="close" />
              </button>
            </div>
            <div className="wkp-upload-file-text">You can continue working while this file is being processed.</div>
          </>
        )}
        {elementData?.uploadedFile?.status === FILE_COMPLETED_STATUS && (
          <>
            <div className="wkp-upload-file-status">
              <div className="wkp-upload-file-elements">
                <div className="wkp-upload-file-success-icon">
                  <BTIcon icon="check-circle" />
                </div>
                <span
                  style={{ fontWeight: 'bold' }}
                  title={getElementTitle(getUploadedInputFile() || elementData?.uploadedFile)}
                >
                  {getTruncatedName(getUploadedInputFile() || elementData?.uploadedFile, fileNameCharsLimit)}
                </span>
              </div>
              <button className="wkp-progress-bar-btn-close" type="button" onClick={onCloseJob}>
                <BTIcon icon="close" />
              </button>
            </div>
            {![FILE_IMPORT_RUNNING_STATUS, FILE_COMPLETED_STATUS].includes(elementData?.import?.status) && (
              <div className="wkp-upload-file-text">Next, specify where your data is located within the file.</div>
            )}
            {![FILE_IMPORT_RUNNING_STATUS, FILE_COMPLETED_STATUS].includes(elementData?.import?.status) && (
              <BTButton
                btStyle="primary"
                className="wkp-select-data-within-file"
                id="select-data-within-file"
                onClick={onSelectDataWithinFile}
              >
                SELECT DATA WITHIN FILE
              </BTButton>
            )}
          </>
        )}
      </>
    );
  };

  const renderImportFilePill = () => {
    return (
      <>
        {elementData?.import?.status === FILE_IMPORT_RUNNING_STATUS && (
          <>
            <div className="wkp-upload-file-status">
              <div className="wkp-upload-file-elements">
                <div className="wkp-upload-file-spinner-wrapper">
                  <BTSpinner size="1x" />
                </div>
                <span style={{ fontWeight: 'bold' }} title={'Determining fields and data types'}>
                  Determining fields and data types
                </span>
              </div>

              <button className="wkp-progress-bar-btn-close" type="button" onClick={onCancelImport}>
                <BTIcon icon="close" />
              </button>
            </div>
            <div className="wkp-upload-file-text">This may take several minutes.</div>
            <div className="wkp-upload-file-text">You can continue working while this file is being processed.</div>
          </>
        )}
        {elementData?.import?.status === FILE_COMPLETED_STATUS && (
          <>
            <div className="wkp-upload-file-status">
              <div className="wkp-upload-file-elements">
                <div className="wkp-upload-file-success-icon">
                  <BTIcon icon="check-circle" />
                </div>
                <span style={{ fontWeight: 'bold' }} title={'Determining fields and data types'}>
                  Determining fields and data types
                </span>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  const renderSourceFilesHeader = () => {
    return sourceFiles.map(
      ({ id, sourceFileId, activeVersion, sheetName, headerRows, headersStartAt, bucket, fileKey }, index) => (
        <div key={index} className="wkp-source-file-name">
          <span
            title={
              getName(getUploadedInputFile() || elementData?.uploadedFile)?.length > getFileNameCharsLimit
                ? getName(getUploadedInputFile() || elementData?.uploadedFile)
                : ''
            }
          >
            {getTruncatedName(getUploadedInputFile() || elementData?.uploadedFile, getFileNameCharsLimit)}
          </span>{' '}
          {canEditWorkflow && (
            <BTButton
              aria-label="editFile"
              btStyle="link"
              btType="edit"
              id="df-edit-file-settings"
              onClick={() =>
                editFile(
                  id ?? sourceFileId,
                  getUploadedInputFile() || elementData?.uploadedFile,
                  sheetName,
                  headerRows,
                  headersStartAt,
                  bucket,
                  fileKey,
                  activeVersion
                )
              }
            >
              {' '}
            </BTButton>
          )}
          {deleteSourceFileFlag && !sourceFiles[0]?.error && canEditWorkflow ? (
            <BTButton
              btStyle="link"
              btType="delete"
              onClick={() => deleteSourceFileModal(getName(elementData?.uploadedFile), id ?? sourceFileId)}
            >
              {' '}
            </BTButton>
          ) : (
            ''
          )}
          {getSheetName(sheetName, activeVersion) &&
            sheetHeaderDetails(
              getSheetName(sheetName, activeVersion),
              (headerRows = activeVersion ? activeVersion.headerRows : headerRows),
              (headersStartAt = activeVersion ? activeVersion.headersStartAt : headersStartAt)
            )}
          <BTCheckbox
            checked={elementData?.appendDataSource?.appendSource}
            disabled={!canEditWorkflow}
            id="checkbox"
            label="Append data source details to each row"
            onChange={e => onChangeAppendDataSource(e.target.checked)}
          />
        </div>
      )
    );
  };

  const renderFileColumns = () => {
    return (
      <>
        <h5>Columns</h5>
        <table className="wkp-fields-list">
          <thead>
            <tr>
              <th>Column</th>
              <th>Data type</th>
            </tr>
          </thead>
          <tbody>
            {fields?.map(({ original_name, name, type }, index) => (
              <tr key={index}>
                <td className="wkp-no-paddings">
                  <BTForm.FormGroup>
                    <div className="wkp-column-items wkp-cursor-default">{original_name || name}</div>
                  </BTForm.FormGroup>
                </td>
                <td className="wkp-no-paddings">
                  <BTComboBox
                    popOutMenu
                    defaultValue={dataTypeOptions.find(o => o.value === type)}
                    disabled={!canEditWorkflow}
                    maxMenuHeight={100}
                    options={dataTypeOptions}
                    value={dataTypeOptions.find(o => o.value === type)}
                    onChange={onFieldTypeChange.bind(null, index)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {isPaginationEnabled.current && total > INSPECTOR_PAGE_SIZE && (
          <PaginationContainer
            endIndex={page * INSPECTOR_PAGE_SIZE}
            page={page}
            setPage={setPage}
            startIndex={(page - 1) * INSPECTOR_PAGE_SIZE}
            totalFields={total}
          />
        )}
      </>
    );
  };

  const renderMultiUploadJobWarning = () => {
    return (
      <div className="wkp-inspector-warning">
        <BTAlert key={workingElement.id} btStyle={'warning'}>
          <p>Unable to upload another file until the current import is complete.</p>
        </BTAlert>
      </div>
    );
  };

  const renderSourceFileStatusPanel = () => {
    const ingestRunning = workingElement?.id !== ingestWithElementId() && isIngestRunning();
    const uploadRunning = workingElement?.id !== uploadingWithImportElementId() && isUploadingWithImport();

    if ((ingestRunning || uploadRunning) && inputFileImportFlag && inputSingleFileImportFlag) {
      return renderMultiUploadJobWarning();
    }
    return (
      <>
        {!sourceFiles?.length && inputFileImportFlag && elementData?.uploadedFile && renderUploadFilePill()}
        {!sourceFiles?.length && inputFileImportFlag && renderImportFilePill()}
        {sourceFiles?.length > 0 && !sourceFiles[0]?.error && renderSourceFilesHeader()}
      </>
    );
  };

  const canEditWorkflow = useCanEditWorkflow();

  return (
    <div className="wkp-input-element-inspector">
      <h5>Source Data Files</h5>
      <div className="wkp-source-file-status">{renderSourceFileStatusPanel()}</div>
      {elementData?.fields?.length > 0 && sourceFiles?.length > 0 && !sourceFiles[0]?.error && renderFileColumns()}
    </div>
  );
};

export default FilePropertiesPanel;
