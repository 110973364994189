import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { getPresignedUrl, uploadFileToBucket, uploadSourceFile } from '../../shared/apis';
import { getWorkbookInfo } from '../FileDialog/apis';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import { WKP_INGEST_REWRITE, WKP_INPUT_FILE_IMPORT } from '../../../../constants/featureFlags';
import { DATAFLOW_RAW_FILE_UPLOAD_JOB_TYPE } from '../../../_shared/jobs/jobTypes';
import qs from 'qs';

import {
  useJobProcessor,
  JOB_PROCESSOR_STATUS_COMPLETED,
  JOB_PROCESSOR_STATUS_FAILED,
  JOB_PROCESSOR_STATUS_RUNNING,
} from '../../DataFlowJobsContext';
import { MAX_SIZE_FILE } from './constants';
import { FILE_UPLOAD_RUNNING_STATUS } from '../FileSettings/FilePropertiesStatusTypes';

export default function useUploadSourceFile(dataFlowState, dataFlowActions) {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState({});
  const [uploadToS3Error, setUploadToS3Error] = useState();
  const [uploadValidationError, setUploadValidationError] = useState(null);
  const { id: dataFlowId, workingElement, taxPeriod } = dataFlowState;
  const { setFilePropertiesDialog, resetSaveMenuDirty, wkpFileImportProperties, setWKPFileImportProperties } =
    dataFlowActions;
  const [uploadedFilesByInput, setUploadedFilesByInput] = useState({});
  const { addJob, bindOnStatusChanged } = useJobProcessor();

  const uploadFileS3 = useCallback(
    async sampleFile => {
      const uploadResult = await uploadSourceFile(sampleFile);
      const fileExtension = sampleFile.name.split('.').pop();
      resetSaveMenuDirty();

      const isCsvFile = fileExtension === 'csv';
      let uploadedWorkbookInfo;

      // Display Source File Dialog
      setFilePropertiesDialog({ popup: true, loading: true });

      if (!isCsvFile) {
        uploadedWorkbookInfo = await getWorkbookInfo(uploadResult);
      }

      setFilePropertiesDialog({
        popup: true,
        loading: false,
        isCsvFile,
        fileDialogTaxPeriod: taxPeriod,
        fileName: sampleFile.name,
        sheets: !isCsvFile
          ? uploadedWorkbookInfo.sheetNames.map(sheet => {
              return { value: sheet.name, label: sheet.name };
            })
          : [],
        sourceInfo: {
          sourceData: !isCsvFile ? uploadedWorkbookInfo.sourceData : uploadResult,
          dataFlowId,
          inputId: workingElement.id,
          elementData: { ...workingElement.elementData, containsNewSourceFiles: true },
        },
      });

      setUploadError(prevState => ({ ...prevState, [workingElement.id]: undefined }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataFlowId, workingElement, setFilePropertiesDialog, resetSaveMenuDirty, taxPeriod]
  );

  const uploadFileStaging = useCallback(
    sampleFile => {
      resetSaveMenuDirty();
      setWKPFileImportProperties({
        popup: true,
        loading: false,
        fileDialogTaxPeriod: taxPeriod,
        fileName: sampleFile.name,
        uploadFile: sampleFile,
      });
    },
    [resetSaveMenuDirty, setWKPFileImportProperties, taxPeriod]
  );

  const onSaveFile = useCallback(
    async (file, fileName) => {
      try {
        await uploadSourceFileWithImport(
          workingElement.id,
          file,
          fileName,
          data => setUploadedFilesByInput({ ...uploadedFilesByInput, [workingElement.id]: data }),
          setUploadToS3Error
        );
      } catch (err) {
        setUploadToS3Error(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workingElement, setUploadToS3Error]
  );

  const onDrop = useCallback(
    async files => {
      const [sampleFile] = files;
      try {
        if (isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT)) {
          if (sampleFile.size > MAX_SIZE_FILE) {
            setUploadValidationError("The file you're attempting to upload is too large to process.");
            return;
          }
          setUploadValidationError(null);
          uploadFileStaging(sampleFile);
        } else {
          setIsUploading(true);
          await uploadFileS3(sampleFile);
        }
      } catch (err) {
        let error;

        if (err.code) {
          const { code, message } = err;
          error = ['WKP2001', 'WKP2002', 'WKP2003', 'WKP2006', 'WKP2007'].includes(code)
            ? 'Failed to parse sample file. Please make sure it is a valid csv file.'
            : 'Failed to upload sample file. Please check your internet connection and try again.';

          if (code === 'WKP2015') {
            error = message;
          }
        } else {
          error = 'Invalid file format. Source file must be of type *.xlsx or *.csv.';
        }

        setUploadError(prevState => ({ ...prevState, [workingElement.id]: error }));
      } finally {
        setIsUploading(false);
      }
    },
    [workingElement, uploadFileS3, uploadFileStaging]
  );

  const dropzone = useDropzone({
    accept: ['.csv', '.xlsx'],
    onDrop: onDrop,
    multiple: false,
  });

  const updateWKPFileImportState = (name, inputId) => {
    const fileExtension = name.split('.').pop();
    const isCsvFile = fileExtension === 'csv';

    setWKPFileImportProperties({
      ...wkpFileImportProperties,
      fileName: name,
      isCsvFile: isCsvFile,
      inputId,
    });
  };

  const uploadSourceFileWithImport = useCallback(
    async (inputId, sourceFile, fileName, setUploadedFilesByInput, setUploadToS3Error) => {
      let presignedUrlQuery;
      if (isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT)) {
        presignedUrlQuery = qs.stringify({ fileName });
      } else {
        presignedUrlQuery = qs.stringify({ fileName: sourceFile.name });
      }

      const name = fileName;

      const setUploadedFileError = () => {
        setUploadedFilesByInput({
          status: 'error',
          name,
        });
        setUploadToS3Error(uploadS3Error);
      };

      let presignedUrlData = {};
      try {
        presignedUrlData = await getPresignedUrl(presignedUrlQuery);
      } catch (error) {
        setUploadedFileError();
        throw error;
      }
      const { url, key, bucket } = presignedUrlData;
      const location = key;
      const fileData = { name, location, bucket };
      const uploadS3Error = 'upload to s3 error';

      if (isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT)) {
        try {
          if (isFeatureFlagEnabled(WKP_INGEST_REWRITE)) {
            const onStatusChange = status => {
              if (status === JOB_PROCESSOR_STATUS_RUNNING) {
                setUploadToS3Error(undefined);
                setUploadedFilesByInput({
                  ...fileData,
                  status: 'uploading',
                });
              }

              if (status === JOB_PROCESSOR_STATUS_COMPLETED) {
                setUploadedFilesByInput({
                  ...fileData,
                  status: 'completed',
                });
                updateWKPFileImportState(name, inputId);
              }

              if (status === JOB_PROCESSOR_STATUS_FAILED) {
                setUploadedFileError();
              }
            };
            bindOnStatusChanged(workingElement.id, onStatusChange);

            addJob({
              processId: workingElement.id,
              type: DATAFLOW_RAW_FILE_UPLOAD_JOB_TYPE,
              batchId: dataFlowId,
              fileName: fileName,
              jobPayload: {
                fileName,
              },
              callback: uploadFileToBucket,
              params: [url, sourceFile],
            });
          } else {
            setUploadToS3Error(undefined);
            setUploadedFilesByInput({
              ...fileData,
              status: 'uploading',
            });

            await uploadFileToBucket(url, sourceFile);

            setUploadedFilesByInput({
              ...fileData,
              status: 'completed',
            });
            updateWKPFileImportState(name, inputId);
          }
        } catch (error) {
          setUploadedFileError();
        }
      } else {
        const result = await uploadFileToBucket(url, sourceFile);
        if (!result.ok) {
          throw new Error('Failed to upload source file. Please check your internet connection and try again.');
        }
      }

      return { name: sourceFile.name, path: key, bucket };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workingElement, getPresignedUrl, uploadFileToBucket, setUploadedFilesByInput]
  );

  const isUploadingWithImport = useCallback(() => {
    const status = Object.values(uploadedFilesByInput).some(value => value?.status === FILE_UPLOAD_RUNNING_STATUS);
    return status;
  }, [uploadedFilesByInput]);

  const uploadingWithImportElementId = useCallback(() => {
    const entry = Object.entries(uploadedFilesByInput).find(
      ([key, value]) => value?.status === FILE_UPLOAD_RUNNING_STATUS
    );
    return entry ? entry[0] : null;
  }, [uploadedFilesByInput]);

  return {
    uploadError,
    setUploadError,
    uploadToS3Error,
    setUploadToS3Error,
    uploadValidationError,
    setUploadValidationError,
    dropzone,
    isUploading,
    onSaveFile,
    uploadedFilesByInput,
    setUploadedFilesByInput,
    isUploadingWithImport,
    uploadingWithImportElementId,
  };
}
