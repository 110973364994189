import React, { useContext, useMemo, useRef, useState } from 'react';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import Spinner from '../../../_shared/Spinner';
import { BTButton, BTComboBox, BTForm, BTCheckbox } from '@btas/jasper';
import { DELETE_SOURCE_FILE } from '../../../../constants/featureFlags';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import { removeUplodFilePermanently, validateIsPermanentDelete } from '../InputElementInspector/removeSourceFile';
import { removeSourceFile } from '../InputElementInspector/apis';
import { DATE, NUMERIC, TEXT } from '../shared/fieldTypes';
import { getSheetNames } from '../FileDialog/apis';
import { useHistory } from 'react-router-dom';
import '../InputElementInspector/styles.scss';
import { useCanEditWorkflow } from '../../../_shared/UserPermissionsContext';
import { WKP_CONFIG_PANEL_PAGINATION } from '../../../../constants/featureFlags';
import { INSPECTOR_PAGE_SIZE } from '../shared/constants/pagination';
import { PaginationContainer } from '../shared/PaginationContainer';

const FileSettings = ({
  elementId,
  elementData,
  updateData,
  importFile,
  sourceFiles,
  setSourceFiles,
  setOpenSourceFile,
}) => {
  const { dataFlowState, dataFlowActions, showConfirmationModal, sourceFileUpload } = useContext(DataFlowEditorContext);

  const { setFilePropertiesDialog, commitWorkingElement } = dataFlowActions;
  const { workingElement, taxPeriod, id: dataflowId } = dataFlowState;
  const { isUploading } = sourceFileUpload;

  const deleteSourceFileFlag = isFeatureFlagEnabled(DELETE_SOURCE_FILE);
  const history = useHistory();

  const total = elementData.fields.length;
  const isPaginationEnabled = useRef(isFeatureFlagEnabled(WKP_CONFIG_PANEL_PAGINATION));
  const [page, setPage] = useState(1);

  const fields = useMemo(() => {
    const start = (page - 1) * INSPECTOR_PAGE_SIZE;
    const end = page * INSPECTOR_PAGE_SIZE;

    if (isPaginationEnabled.current === false) {
      return elementData.fields;
    }

    return elementData.fields.slice(start, end);
  }, [elementData.fields, page]);

  const dataTypeOptions = [
    { label: 'Text', value: TEXT },
    { label: 'Number', value: NUMERIC },
    { label: 'Date', value: DATE },
  ];

  const sheetHeaderDetails = (sheetName, headerRows, headersStartAt) => (
    <p className="wkp-input-sheet-details">
      Sheet: <span title={sheetName.length > 23 ? sheetName : ''}>{truncatedName(sheetName, 23)}</span> | Headers starts
      at: {headersStartAt} | Header row: {headerRows}
    </p>
  );

  const deleteSourceFileModal = async (name, sourceFileId) => {
    if (deleteSourceFileFlag) {
      const deleteResp = await validateIsPermanentDelete(dataFlowState.id, elementId);
      if (deleteResp.permanentDeleteInfo && deleteResp.permanentDeleteInfo.length > 0) {
        //we have to check both file.id and sourceFileId because pending files will be retrieved
        //as source files versions and won't have id
        showConfirmationModal(
          'Delete Source File',
          `The source file '${name}' will be permanently deleted. Are you sure?`,
          'Delete',
          'Cancel',
          'warning',
          async () => {
            await removeSourceFile(sourceFileId, dataFlowState.id, elementId);
            await removeUplodFilePermanently(deleteResp.permanentDeleteInfo, elementData.integrationType.toLowerCase());
            const newSourceFiles = sourceFiles.filter(
              file => file.id !== sourceFileId && file.sourceFileId !== sourceFileId
            );
            setSourceFiles(newSourceFiles);
            updateData({
              fields: undefined,
              integrationType: null,
              containsNewSourceFiles: false,
              configFields: undefined,
              hasSourceFileUploaded: false,
              appendDataSource: undefined,
            });
            commitWorkingElement();
            history.replace(`/data-flows/${dataflowId}/editor`);
          }
        );
      } else {
        await removeSourceFile(sourceFileId, dataFlowState.id, elementId);
        const newSourceFiles = sourceFiles.filter(
          file => file.id !== sourceFileId && file.sourceFileId !== sourceFileId
        );
        setSourceFiles(newSourceFiles);
        updateData({ containsNewSourceFiles: false, isSourceFileDeleted: true });
        commitWorkingElement();
        history.replace(`/data-flows/${dataflowId}/editor`);
      }
    }
  };

  const getFileNameCharsLimit = 25;

  const getName = uploadedFile => {
    return uploadedFile?.name ?? null;
  };

  const truncatedName = (name, length) => {
    return name?.length > length ? `${name.substr(0, length)}...` : name;
  };

  const getTruncatedName = (uploadedFile, length) => {
    const fileName = getName(uploadedFile);
    return truncatedName(fileName, length);
  };

  const getSheetName = (sheetName, activeVersion) => {
    return activeVersion ? activeVersion.sheetName : sheetName;
  };

  const onFieldTypeChange = (pageIndex, { value }) => {
    const index = isPaginationEnabled.current ? (page - 1) * INSPECTOR_PAGE_SIZE + pageIndex : pageIndex;
    const newFields = [...elementData.fields];
    const { derived_date_format: derivedDateFormat } = newFields[index];
    //If field type is not a date, date format should be empty. Otherwise, check if date format has been derived. If not, use default date format.
    const newDateFormatValue =
      value === 'date' ? (derivedDateFormat ? derivedDateFormat : "yyyy-MM-dd'T'HH:mm:ss.SSSXXX") : '';

    newFields[index] = { ...elementData.fields[index], type: value, date_format: newDateFormatValue };

    updateData({ fields: newFields });
  };

  const onChangeAppendDataSource = checked => {
    const { sheetName, uploadedFile } = sourceFiles[0];
    const { name, taxPeriod } = uploadedFile;
    const newFields = [...elementData?.fields];
    const appendDataSource = { appendSource: checked, fileName: name, sheetName, taxPeriod };
    if (checked) {
      const dataSourceCount = elementData?.fields?.reduce(
        (acum, field) =>
          field.name?.includes('Data Source') || field.original_name?.includes('Data Source') ? acum + 1 : acum,
        0
      );
      const dataSourceName = dataSourceCount > 0 ? `Data Source.${dataSourceCount}` : 'Data Source';
      newFields.push({ name: dataSourceName, type: 'text', date_format: 'MM/dd/yyyy' });
    } else {
      newFields.pop();
    }
    updateData({ ...elementData, fields: newFields, appendDataSource });
  };

  const editFile = async (
    sourceFileId,
    uploadedFile,
    sheetTabName,
    numHeaders,
    dataStartAt,
    fileBucket,
    fileKey,
    activeVersion
  ) => {
    const name = getName(uploadedFile);

    let path = fileKey,
      bucket = fileBucket,
      sheetNames = null,
      headerRows = numHeaders,
      headersStartAt = dataStartAt;

    const isCsvFile = activeVersion ? !activeVersion.sheetName : !sheetTabName;

    if (activeVersion) {
      path = activeVersion.fileKey;
      bucket = activeVersion.bucket;
      headerRows = activeVersion.headerRows;
      headersStartAt = activeVersion.headersStartAt;
    }

    if (!isCsvFile) {
      path = uploadedFile.fileLocation.fileKey; // get original xlsx path for reuse

      const result = await getSheetNames({ bucket, path, name });
      sheetNames = result.sheetNames;
    }

    setFilePropertiesDialog({
      popup: true,
      loading: false,
      fileName: name,
      fileDialogTaxPeriod: uploadedFile?.taxPeriod ?? taxPeriod,
      isCsvFile,
      sheets: !isCsvFile
        ? sheetNames.map(sheet => {
            return { value: sheet.name, label: sheet.name };
          })
        : [],
      sourceInfo: {
        sourceData: { bucket, name, path },
        dataFlowId: dataFlowState.id,
        inputId: elementId,
        elementData: { ...workingElement.elementData, containsNewSourceFiles: true },
      },
      updateSourceFile: true,
      reloadFiles: false,
      savedValues: {
        sheetName: getSheetName(sheetTabName, activeVersion),
        headerRows,
        headersStartAt,
      },
      sourceFileId,
    });
  };
  const canEditWorkflow = useCanEditWorkflow();
  return (
    <div className="wkp-input-element-inspector">
      <h5>Source Data Files</h5>
      <div className="wkp-source-file-list">
        {isUploading ? (
          <Spinner />
        ) : sourceFiles?.length > 0 && !sourceFiles[0]?.error ? (
          sourceFiles.map(
            (
              { id, sourceFileId, uploadedFile, activeVersion, sheetName, headerRows, headersStartAt, bucket, fileKey },
              index
            ) => (
              <div key={index} className="wkp-source-file-name">
                <span title={getName(uploadedFile)?.length > getFileNameCharsLimit ? getName(uploadedFile) : ''}>
                  {getTruncatedName(uploadedFile, getFileNameCharsLimit)}
                </span>{' '}
                {canEditWorkflow && (
                  <BTButton
                    aria-label="editFile"
                    btStyle="link"
                    btType="edit"
                    id="df-edit-file-settings"
                    onClick={() =>
                      editFile(
                        id ?? sourceFileId,
                        uploadedFile,
                        sheetName,
                        headerRows,
                        headersStartAt,
                        bucket,
                        fileKey,
                        activeVersion
                      )
                    }
                  >
                    {' '}
                  </BTButton>
                )}
                {deleteSourceFileFlag && !sourceFiles[0]?.error && canEditWorkflow ? (
                  <BTButton
                    btStyle="link"
                    btType="delete"
                    onClick={() => deleteSourceFileModal(getName(uploadedFile), id ?? sourceFileId)}
                  >
                    {' '}
                  </BTButton>
                ) : (
                  ''
                )}
                {getSheetName(sheetName, activeVersion) &&
                  sheetHeaderDetails(
                    getSheetName(sheetName, activeVersion),
                    (headerRows = activeVersion ? activeVersion.headerRows : headerRows),
                    (headersStartAt = activeVersion ? activeVersion.headersStartAt : headersStartAt)
                  )}
                <BTCheckbox
                  checked={elementData?.appendDataSource?.appendSource}
                  disabled={!canEditWorkflow}
                  id="checkbox"
                  label="Append data source details to each row"
                  onChange={e => onChangeAppendDataSource(e.target.checked)}
                />
              </div>
            )
          )
        ) : (
          <>{importFile()}</>
        )}
      </div>
      <h5>Columns</h5>
      <table className="wkp-fields-list">
        <thead>
          <tr>
            <th>Column</th>
            <th>Data type</th>
          </tr>
        </thead>
        <tbody>
          {fields.map(({ original_name, name, type }, index) => (
            <tr key={index}>
              <td className="wkp-no-paddings">
                <BTForm.FormGroup>
                  <div className="wkp-column-items wkp-cursor-default">{original_name || name}</div>
                </BTForm.FormGroup>
              </td>
              <td className="wkp-no-paddings">
                <BTComboBox
                  popOutMenu
                  aria-label={`combobox-${index}`}
                  defaultValue={dataTypeOptions.find(o => o.value === type)}
                  disabled={!canEditWorkflow}
                  maxMenuHeight={100}
                  options={dataTypeOptions}
                  value={dataTypeOptions.find(o => o.value === type)}
                  onChange={onFieldTypeChange.bind(null, index)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isPaginationEnabled.current && total > INSPECTOR_PAGE_SIZE && (
        <PaginationContainer
          endIndex={page * INSPECTOR_PAGE_SIZE}
          page={page}
          setPage={setPage}
          startIndex={(page - 1) * INSPECTOR_PAGE_SIZE}
          totalFields={total}
        />
      )}
    </div>
  );
};

export default FileSettings;
