import React, { useContext, useEffect, useMemo, useState } from 'react';
import { BTComboBox, BTAlert, BTForm } from '@btas/jasper';
import ElementInspector from './shared/ElementInspector';
import { NUMERIC, TEXT, DATE } from './shared/fieldTypes';
import Spinner from '../../_shared/Spinner';
import { DataFlowEditorContext } from './DataFlowEditorContext';
import { useCanEditWorkflow } from '../../_shared/UserPermissionsContext';
const DEFAULT_FIELDS = [];

export const TransposeElementInspector = ({ elementId, elementData, elementType, updateData, isDirty }) => {
  const {
    dataFlowState,
    previewState,
    dataFlowActions: { syncWorkingElement },
  } = useContext(DataFlowEditorContext);
  const { isWaiting, elementConfiguration, error } = previewState;
  const canEditWorkflow = useCanEditWorkflow();

  const [fieldErrors, setFieldErrors] = useState({});
  const { workingElement } = dataFlowState;
  const dataTypeOptions = [
    { label: 'Text', value: TEXT },
    { label: 'Number', value: NUMERIC },
    { label: 'Date', value: DATE },
  ];

  const [generalError] = useState({
    message: undefined,
  });

  const fields = elementData.fields || DEFAULT_FIELDS;

  const sourceFields = elementData.sourceFields;

  useEffect(() => {
    let element = elementConfiguration(workingElement.id);

    if (element && element.fields) {
      syncWorkingElement({ fields: element.fields });
    }
  }, [syncWorkingElement, elementConfiguration, workingElement?.id]);

  useEffect(() => {
    validateFields(fields);
    function validateFields(fieldList) {
      const newFields = fieldList;

      const newErrors = {};
      newFields?.forEach((field, index) => {
        const error = validateName(index, field.name);
        if (error) {
          newErrors[index] = error;
        }
      });

      setFieldErrors(newErrors);
    }

    function validateName(index, name) {
      if (name === '') {
        return 'Column name is required.';
      } else {
        if (fields.find((f, fIndex) => f.name === name && fIndex !== index)) {
          return 'Column name must be unique.';
        } else {
          return null;
        }
      }
    }
  }, [fields]);

  useEffect(() => {
    if (error?.statusCode === 'WKP2018' && !elementData.exceedsColumnLimit && elementData.fields.length > 0) {
      updateData({ exceedsColumnLimit: true });
    }
  }, [error?.statusCode, elementData.exceedsColumnLimit, elementData.fields.length, updateData]);

  useEffect(() => {
    //Will rerender component when we update exceedsColumnLimit to run transpose validation again
    syncWorkingElement();
  }, [elementData.exceedsColumnLimit, syncWorkingElement]);

  const onFieldTypeChange = (index, { value }) => {
    const newFields = [...elementData.fields];
    newFields[index] = { ...elementData.fields[index], type: value };

    //If field type is not a date, date format should be empty. Otherwise, check if date format has been derived. If not, use default date format.
    if (value === 'date') {
      const { derived_date_format: derivedDateFormat } = newFields[index];
      const newDateFormatValue = derivedDateFormat ? derivedDateFormat : "yyyy-MM-dd'T'HH:mm:ss.SSSXXX";

      newFields[index].date_format = newDateFormatValue;
    }

    updateData({ fields: newFields, sourceFields });
  };

  const alerts = useMemo(() => {
    const filteredErrors = [...new Set(Object.values(fieldErrors))];

    if (elementData.exceedsColumnLimit && elementData.sourceFields.length > 0) {
      const errorMessage = 'Transposing this dataset would cause the number of columns to exceed the limit of 500.';
      const errorMesageWithLink = (
        <>
          If this is not what you were attempting to do, consult the{' '}
          <a href="/help/index.html?article=dataTransformationExamples" target="_blank">
            Data Transformation Examples
          </a>{' '}
          for help with your use case.
        </>
      );
      return [{ style: 'danger', content: [<p key={0}>{errorMessage}</p>, <p key={1}>{errorMesageWithLink}</p>] }];
    } else {
      return [
        ...(Object.values(filteredErrors).length > 0
          ? [{ style: 'danger', content: Object.values(filteredErrors).map((err, key) => <p key={key}>{err}</p>) }]
          : []),
      ];
    }
  }, [fieldErrors, elementData.exceedsColumnLimit, elementData.sourceFields?.length]);

  const showFields = useMemo(() => {
    return elementData.exceedsColumnLimit ? (elementData.exceedsColumnLimit ? false : true) : true;
  }, [elementData.exceedsColumnLimit]);

  return (
    <ElementInspector {...{ alerts, elementData, elementId, elementType, updateData, isDirty }}>
      <div className="wkp-input-element-inspector">
        {fields.length > 0 && !isWaiting && showFields ? (
          <>
            <h5>Columns</h5>
            <table className="wkp-fields-list">
              <thead>
                <tr>
                  <th>Column</th>
                  <th>Data type</th>
                </tr>
              </thead>
              <tbody>
                {elementData.fields?.map(({ original_name, name, type }, index) => (
                  <tr key={index}>
                    <td className="wkp-no-paddings">
                      <BTForm.FormGroup>
                        <div className="wkp-column-items wkp-cursor-default">{original_name || name}</div>
                      </BTForm.FormGroup>
                    </td>
                    <td className="wkp-no-paddings">
                      <BTComboBox
                        popOutMenu
                        defaultValue={dataTypeOptions.find(o => o.value === type)}
                        disabled={!canEditWorkflow}
                        maxMenuHeight={100}
                        options={dataTypeOptions}
                        onChange={onFieldTypeChange.bind(null, index)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : isWaiting ? (
          <Spinner />
        ) : null}
        <BTAlert
          appear
          dismissible
          fixed
          btStyle="danger"
          visible={generalError.message !== undefined}
          onDismiss={() => {}}
        >
          {generalError ? generalError.message : ''}
        </BTAlert>
      </div>
    </ElementInspector>
  );
};
