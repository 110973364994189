import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { useInputSourceFiles } from './useInputSourceFiles';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import { BTComboBox, BTForm, BTButton } from '@btas/jasper';
import { NUMERIC, TEXT, DATE } from '../shared/fieldTypes';
import { WKP_CONFIG_PANEL_PAGINATION } from '../../../../constants/featureFlags';
import './styles.scss';
import { format } from 'date-fns';
import { workflowAutomationUrl } from '../../../../configs/params';
import { useHistory } from 'react-router-dom';
import { removeSourceFile, removeUplodFilePermanently, validateIsPermanentDelete } from './removeSourceFile';
import { PaginationContainer } from '../shared/PaginationContainer';
import { INSPECTOR_PAGE_SIZE } from '../shared/constants/pagination';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import { getFASourceFileInfo } from './apis';
import { useTrackInteractiveClick } from '../../../_shared/EventTrackingContext';
import { itemTypes } from '../../../_shared/EventTrackingContext/utils';

const FAInputSubSourceDataTypes = ({ elementId, elementData, elementType, updateData, isDirty }) => {
  const trackEvent = useTrackInteractiveClick();
  const { dataFlowState, dataFlowActions, showConfirmationModal } = useContext(DataFlowEditorContext);
  const { commitWorkingElement } = dataFlowActions;
  const { setFAInputProperties } = dataFlowActions;
  const { faInputProperties, id: dataflowId } = dataFlowState;

  const [jobRunAt, setJobRunAt] = useState('');

  const history = useHistory();

  const dataTypeOptions = [
    { label: 'Text', value: TEXT },
    { label: 'Number', value: NUMERIC },
    { label: 'Date', value: DATE },
  ];

  const total = elementData.fields?.length;
  const isPaginationEnabled = useRef(isFeatureFlagEnabled(WKP_CONFIG_PANEL_PAGINATION));
  const [page, setPage] = useState(1);

  const fields = useMemo(() => {
    const start = (page - 1) * INSPECTOR_PAGE_SIZE;
    const end = page * INSPECTOR_PAGE_SIZE;

    if (isPaginationEnabled.current === false) {
      return elementData.fields;
    }

    return elementData.fields?.slice(start, end);
  }, [elementData.fields, page]);

  const getFileNameCharsLimit = 25;

  const { sourceFiles } = useInputSourceFiles(
    dataFlowState.id,
    elementId,
    elementData?.pendingSourceFileVersionId,
    false
  );

  const getName = uploadedFile => {
    return uploadedFile?.name ?? null;
  };

  const truncatedName = (name, length) => {
    return name?.length > length ? `${name.substr(0, length)}...` : name;
  };

  const getTruncatedName = (uploadedFile, length) => {
    const fileName = getName(uploadedFile);
    return truncatedName(fileName, length);
  };

  const onFieldTypeChange = (pageIndex, { value }) => {
    const index = isPaginationEnabled.current ? (page - 1) * INSPECTOR_PAGE_SIZE + pageIndex : pageIndex;
    const newFields = [...elementData.fields];
    const { derived_date_format: derivedDateFormat } = newFields[index];
    //If field type is not a date, date format should be empty. Otherwise, check if date format has been derived. If not, use default date format.
    const newDateFormatValue =
      value === 'date' ? (derivedDateFormat ? derivedDateFormat : "yyyy-MM-dd'T'HH:mm:ss.SSSXXX") : '';

    newFields[index] = { ...elementData.fields[index], type: value, date_format: newDateFormatValue };

    updateData({ fields: newFields });
  };

  const editFile = (sourceFileId, uploadFileName, activeVersion) => {
    const sourceFileVersionId = elementData.pendingSourceFileVersionId ?? activeVersion?.id;
    setFAInputProperties({
      ...faInputProperties,
      showDataType: false,
      isEdit: true,
      uploadFileName,
      sourceFileId,
      sourceFileVersionId,
    });
  };

  const deleteSourceFileModal = async (name, sourceFileId) => {
    const deleteResp = await validateIsPermanentDelete(dataFlowState.id, elementId);

    if (deleteResp?.permanentDeleteInfo && deleteResp?.permanentDeleteInfo.length > 0) {
      showConfirmationModal(
        'Delete Source File',
        `The source file '${name}' will be permanently deleted. Are you sure?`,
        'Delete',
        'Cancel',
        'warning',
        async () => {
          await removeSourceFile(sourceFileId, dataFlowState.id, elementId);
          await removeUplodFilePermanently(deleteResp?.permanentDeleteInfo, 'fa');
          updateData({
            fields: undefined,
            integrationType: null,
            containsNewSourceFiles: false,
            configFields: undefined,
            hasSourceFileUploaded: false,
          });
          commitWorkingElement();
          history.replace(`/data-flows/${dataflowId}/editor`);
        }
      );
    } else {
      await removeSourceFile(sourceFileId, dataFlowState.id, elementId);
      updateData({
        fields: undefined,
        integrationType: null,
        containsNewSourceFiles: false,
        configFields: undefined,
        hasSourceFileUploaded: false,
      });
      commitWorkingElement();
      history.replace(`/data-flows/${dataflowId}/editor`);
    }
  };

  const formatJobRunDate = () => {
    if (faInputProperties?.jobRunAt) {
      const splitDates = new Date(faInputProperties.jobRunAt).toLocaleString().split(' ');
      splitDates[1] = splitDates[1].replaceAll('-', ':');
      const formateDate = splitDates.join(' ');
      const jobRunTime = format(new Date(formateDate), 'MM/dd/yyyy hh:mm:ss a');
      setJobRunAt(jobRunTime);
    }
  };

  useEffect(() => {
    // format the date.
    formatJobRunDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faInputProperties?.jobRunAt]);

  useEffect(() => {
    async function loadInputSettings() {
      if (elementData?.hasSourceFileUploaded) {
        const { workFlowName, workflowId, focusPeriod, jobRunAt } = elementData?.configFields;
        const sourceFAFile = await getFASourceFileInfo(workflowId, workFlowName, focusPeriod);
        const { name } = sourceFAFile;

        setFAInputProperties({
          ...faInputProperties,
          processingFile: false,
          workflow: { value: workFlowName },
          focusPeriod,
          uploadFileName: name,
          jobRunAt,
        });
      } else {
        setFAInputProperties({
          ...faInputProperties,
          showDataType: false,
        });
      }
    }
    loadInputSettings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementId, elementData, faInputProperties?.showDataType, elementData?.hasSourceFileUploaded]);

  return (
    <div className="wkp-input-element-inspector">
      <div className="wkp-source-file-list">
        <h5>Data Source</h5>
        {sourceFiles?.map(
          (
            {
              id,
              sourceFileId,
              uploadedFile,
              activeVersion,
              pendingVersionId,
              sheetName,
              headerRows,
              headersStartAt,
              bucket,
              fileKey,
            },
            index
          ) =>
            (!faInputProperties?.retrieving || !faInputProperties?.processingFile) && (
              <div key={index} className="wkp-source-file-name">
                <span
                  className="wkp-input-fa-title"
                  title={getName(uploadedFile)?.length > getFileNameCharsLimit ? getName(uploadedFile) : ''}
                >
                  {getTruncatedName(uploadedFile, getFileNameCharsLimit)}
                </span>{' '}
                <BTButton
                  aria-label="editFAFileBtn"
                  btStyle="link"
                  btType="edit"
                  onClick={() => editFile(id ?? sourceFileId, getName(uploadedFile), activeVersion)}
                >
                  {' '}
                </BTButton>
                {!sourceFiles[0]?.error ? (
                  <BTButton
                    btStyle="link"
                    btType="delete"
                    id="deleteFAFileBtn"
                    name="deleteFAFileBtn"
                    onClick={() => deleteSourceFileModal(getName(uploadedFile), id ?? sourceFileId)}
                  >
                    {' '}
                  </BTButton>
                ) : (
                  ''
                )}
              </div>
            )
        )}
        <p>
          Workflow: {faInputProperties?.workflow?.value || elementData?.configFields?.workFlowName} | Focus period:{' '}
          {faInputProperties?.focusPeriod || elementData?.configFields?.focusPeriod}
        </p>
        <p>
          Data is from the workflow automation job run at {jobRunAt}.
          <a
            href={workflowAutomationUrl + '/fa-wa/jobs'}
            rel="noreferrer"
            target="_blank"
            onClick={() => {
              trackEvent('fa-retrieve-data-button', itemTypes.LI);
            }}
          >
            Open Workflow Automation
          </a>{' '}
          to run the workflow again
        </p>
      </div>
      <h5>Columns</h5>
      <table className="wkp-fields-list">
        <thead>
          <tr>
            <th>Column</th>
            <th>Data type</th>
          </tr>
        </thead>
        <tbody>
          {fields?.map(({ name, type }, index) => (
            <tr key={index}>
              <td className="wkp-no-paddings">
                <BTForm.FormGroup>
                  <div className="wkp-column-items wkp-cursor-default">{name}</div>
                </BTForm.FormGroup>
              </td>
              <td className="wkp-no-paddings">
                <BTComboBox
                  popOutMenu
                  defaultValue={dataTypeOptions.find(o => o.value === type)}
                  maxMenuHeight={100}
                  options={dataTypeOptions}
                  value={dataTypeOptions.find(o => o.value === type)}
                  onChange={onFieldTypeChange.bind(null, index)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isPaginationEnabled.current && total > INSPECTOR_PAGE_SIZE && (
        <PaginationContainer
          endIndex={page * INSPECTOR_PAGE_SIZE}
          page={page}
          setPage={setPage}
          startIndex={(page - 1) * INSPECTOR_PAGE_SIZE}
          totalFields={total}
        />
      )}
    </div>
  );
};

export default FAInputSubSourceDataTypes;
